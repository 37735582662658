<template>
  <el-dialog
    :title="isEdit === true ? '编辑' : '新增'"
    :visible.sync="show"
    width="700px"
    center
  >
    <el-form
      v-if="show"
      :model="form"
      :label-width="formLabelWidth"
      label-position="left"
      ref="form"
      :rules="rules"
      v-loading="loading"
    >
      <el-form-item label="上级菜单" style="width: 100%">
        <el-cascader
          clearable
          :props="{ checkStrictly: true ,emitPath:false}"
          v-model="pids"
          @change="changePids"
          :options="menuData"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="规则" prop="route">
        <el-input v-model.number="form.route" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="方法" prop="method">
        <!-- <el-input v-model="form.method" autocomplete="off"></el-input> -->
        <template>
          <el-select v-model="form.method" placeholder="请选择">
            <el-option
              v-for="item in methodList"
              :label="item.label"
              :value="item.value"
              :key="item.value">
            </el-option>
          </el-select>
        </template>
      </el-form-item>
      <el-form-item label="排序" prop="sort_order">
        <el-input
          v-model.number="form.sort_order"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="图标" prop="icon">
        <el-input v-model.number="form.icon" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="是否启用" prop="is_show">
        <el-switch v-model="form.enable"></el-switch>
      </el-form-item>

      <el-form-item label="菜单" prop="tag_name">
        <el-switch
          v-model="form.menu_type"
          active-value="menu"
          inactive-value="auth"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from "@/api";

export default {
  name: "addForm",
  data() {
    return {
      formLabelWidth: "100px",
      pids: [],
      form: {
        icon: "",
        menu_type: "menu",
        method: "",
        pid: "",
        route: "",
        sort_order: "",
        title: "",
        enable: true,
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        route: [{ required: true, message: "请输入规则", trigger: "blur" }],
        method: [{ required: true, message: "请输入方法", trigger: "change" }],
        sort_order: [
          { required: true, message: "请输入序号", trigger: "blur" },
        ],
        // pids: [
        //   { required: true, message: "请选择", trigger: "blur" },
        // ],
      },
      isEdit: false,
      show: false,
      menuData: [],
      methodList:[
        {label:'GET',value:'GET'},
        {label:'POST',value:'POST'}
      ],
      loading:false
    };
  },
  watch: {},
  methods: {
    changePids(e) {
      console.log(e)
      // if (e && e.length > 0) {
      //   this.form.pid = e[e.length - 1];
      // }
      this.form.pid = e
      console.log(this.form.pid)
    },
    submit() {
      const funName = this.isEdit ? "updateData" : "addData";
      this.$refs.form.validate((valid) => {
        valid ? this[funName]() : "";
      });
    },
    updateData() {
      console.log(this.form)
      this.form.pid ? this.form.pid = this.form.pid : this.form.pid = 0
      API.updateMenu(this.form)
        .then((res) => {
          if(!res.code){
            this.$message.success("编辑成功");
            this.show = false;
            this.$parent.getList();
          }else{
            this.$message.error(res.msg);
            this.show = false;
          }
        })
        .catch(() => {
          this.$message.error("编辑失败");
        });
    },
    editData(data) {
      this.getList()
      this.form = data;
      this.isEdit = true;
      this.show = true;
    },
    clearData() {
      this.form = {
        icon: "",
        menu_type: "menu",
        method: "",
        pid: "",
        route: "",
        sort_order: "",
        title: "",
        enable: true,
      };
    },
    addFormData() {
      this.isEdit = false;
      this.clearData();
      this.show = true;
      this.getList();
      this.pids = ""
    },
    addData() {
      this.form.pid ? this.form.pid = this.form.pid : this.form.pid = 0
      API.createMenu(this.form)
        .then((res) => {
          if(!res.code){
            this.$message.success("创建成功");
            this.show = false;
            this.$parent.getList();
          }else{
            this.$message.error(res.msg);
            this.show = false;
          }
        })
        .catch(() => {
          this.$message.error("创建失败");
        });
    },
    setValue(val) {
      val.forEach((item) => {
        item.value = item.id;
        item.label = item.title;
        item.children = item.child;
        try {
          if (item.children && item.children.length > 0) {
            this.setValue(item.children);
          }
        } catch (error) {
          console.log("error", error);
        }
      });
      return;
    },
    getList() {
      this.loading = true
      API.getMenuList()
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.menuData = res.data.data;
            this.menuData.forEach((item) => {
              item.value = item.id;
              item.label = item.title;
              item.children = item.child;
              try {
                if (item.children && item.children.length > 0) {
                  this.setValue(item.children);
                }
              } catch (error) {
                console.log("error", error);
              }
              // 编辑数据回显
              if(this.isEdit){
                this.pids = this.form.pid
              }
              this.loading = false;
            });
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return;
          } else {
            this.menuData = [];
          }
          this.loading = false;
        })
        .catch(() => {
          //this.menuData = [];
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
